export function validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}
import { isEmail, isEmpty, isFloat, isMongoId, isURL } from 'validator';
// isURL , isNumeric, isMongoId, isFloat, equals, isLength
import { countObjectLength } from './format'

let empty  = value => { return (isEmpty(value)) ? "This field is required" : ""; };
let url    = value => { 
	if (isEmpty(value)) return "";
	return (isURL(value)) ? "" : "This field is url"; 
};
let mongoId= value => { 
	if (isEmpty(value)) return "";
	return (isMongoId(value)) ? "" : "This field is required"; 
};
let float  = value => { 
	if (isEmpty(value)) return "";
	return (isFloat(value)) ? "" : "This field is float number"; 
};
let email  = value => { 
	if (isEmpty(value)) return "";
	return (isEmail(value)) ? "" : "This field is email"; 
};
let length = (value, len) => {
	let err = "";
	if (len.min && value.length < len.min) err = "This field min " + len.min + " characters";
	if (len.max && value.length > len.max) err = "This field max " + len.max + " characters";
	return err;
};	
let validateByOption = (key, option, object) => {
		let err = "";
		let value = object[key];
		value = value.toString();
		if (option.empty)     err = empty(value);
		if (err != "") return err;
		if (option.isUrl) 	  err = url(value);
		if (err != "") return err;
		if (option.isMongoId) err = mongoId(value);
		if (err != "") return err;
		if (option.isFloat)   err = float(value);
		if (err != "") return err;
		if (option.isEmail)   err = email(value);
		if (err != "") return err;
		if (option.length)    err = length(object[key], option.length);
		return err;
};

/**
* isObject == false => validates info [{key, option }]
* isObject == true  => validates info [{isObject, key, validates: [ { key, option }] }]
*/
export function require(object, validates) { 
	let error = {};
	let status = true;
	validates.forEach(validatesInput);
	function validatesInput(val) {
		let { isObject } = val;
		if (!isObject) validateVariable(val, object);
		else validateObject(val);		
	}
	function validateVariable(val, _object) {
		let { key, option }   = val;
		error[key] = validateByOption(key, option, _object);
		if (error[key] != "") status = false;
	}
	function validateObject(val) {
		let { key, validates: objectValidates } = val;
		let objectValue = object[key];
		if (typeof objectValue == 'string') objectValue = JSON.parse(objectValue);
		error[key] = [];
		if (countObjectLength(objectValue) > 0) {
			for (let idx in objectValue) _validate(objectValue[idx], idx);
		}
		function _validate(item, idx) {
			if (!item) return true;
			let err = {};
			objectValidates.forEach(_val => {
				err[_val.key] = validateByOption(_val.key, _val.option, item);
				if (err[_val.key] != "") status = false;
			});
			error[key][idx] = err;
		}	
	}
	
	return { status, error };
}

export function validateRequire(object, key) {
	if (isEmpty(object[key])) return "This field is required";
	return '';
}
export function validateFloat(object, key) {
	if (isFloat(object[key]) == false) return "This field is float number";
	return '';
}

export function validateMobile(mobile) {
    let re = /^\+(?:[0-9] ?){6,14}[0-9]$/;
    return re.test(mobile);
}