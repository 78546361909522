import { NUMBER_YEAR_PRACTIVE } from '../data/config'

let self = module.exports = {
    convertToFt: (value, unit) => {
        value = value ? parseFloat(value) : 0
        switch (unit) {
            case 'm':
                return 3.2808 * value;
            case 'cm':
                return 0.03281 * value;
            case 'mm':
                return 0.00328 * value;
            case 'in':
                return 0.0833 * value;
            default:
                return value;
        }
    },

    convertToSqf: (value, unit) => {
        value = value ? parseFloat(value) : 0
        switch (unit) {
            case 'm':
                return 10.7639 * value;
            case 'cm':
                return 0.00107639 * value;
            case 'mm':
                return 0.00107639 * 0.00107639 * value;
            case 'in':
                return 0.00694444 * value;
            default:
                return value;
        }
    },

    calculatorAvarage: (price1, price2) => {
        if (!price1) {
            return price2
        } else {
            return (parseFloat(price1) + parseFloat(price2)) / 2
        }
    },

    getYearPracticeDs: (ds) => {
        if (!ds.vendor_designer_detail_id || !ds.vendor_designer_detail_id.no_of_yrs_in_practice) {
            return NUMBER_YEAR_PRACTIVE[0]
        }

        return NUMBER_YEAR_PRACTIVE[ds.vendor_designer_detail_id.no_of_yrs_in_practice]
    },

    getYearPracticePM: (ds) => {
        if (!ds.vendor_pm_detail_id || !ds.vendor_pm_detail_id.no_of_yrs_in_practice) {
            return NUMBER_YEAR_PRACTIVE[0]
        }

        return NUMBER_YEAR_PRACTIVE[ds.vendor_pm_detail_id.no_of_yrs_in_practice]
    },

    convertToM: (value, unit) => {
        value = value ? parseFloat(value) : 0
        switch (unit) {
            case 'in':
                return value * 39.37;
            case 'ft':
                return value / 3.2808;
            default:
                return value;
        }
    },

    calculatorArea: (width, height, length, input_type, floor_area = 0) => {
        let floor = floor_area || width * length;
        switch (input_type) {
            case 'WxH':
                return floor || width * height;
                break;
            case 'WxL':
                return floor;
                break;
            case 'HxL':
                return floor || length * height;
                break;
            case 'Wx2+Lx2':
                return (length + width) * 2;
                break;
            case 'Wx2xH+Lx2xH':
                return (length + width) * height * 2;
                break;
            case 'Wx1.2 x Lx1.2':
                return (floor) * 1.2 * 1.2;
                break;
            case 'Wx2xHx1.2 + Lx2xHx1.2':
                return (length + width) * height * 2 * 1.2;
                break;
            case 'W':
                return floor || width;
                break;
            case 'H':
                return height;
                break;
            case 'L':
                return floor || length;
                break;
            default:
                return floor || width * height;
                break;
        }
    },

    calculatorAreaWithFloor: (width, height, length, floor_area, input_type) => {
        switch (input_type) {
            case 'WxH':
                return width * height;
                break;
            case 'WxL':
                return floor_area ? floor_area : width * length;
                break;
            case 'HxL':
                return length * height;
                break;
            case 'W':
                return width;
                break;
            case 'H':
                return height;
                break;
            case 'L':
                return length;
                break;
            default:
                return width * height;
                break;
        }
    },

    convertPrice: (price) => {
        let priceArr = price.split('.')
        let priceInt = parseInt(priceArr[0]).toLocaleString()
        let priceSecond = priceArr[1] || ''
        return `${priceInt}.${priceSecond}`
    },

    convertPriceNumber: (price) => {
        price = parseFloat(price)
        if (isNaN(price)) return '0.00'
        price = price.toFixed(2)
        let priceArr = price.split('.')
        let priceInt = parseInt(priceArr[0]).toLocaleString()
        let priceSecond = priceArr[1] || ''
        return `${priceInt}.${priceSecond}`
    },

    getFloor: (floor) => {
        switch (floor) {
            case 1:
                return floor + 'st';
            case 2:
                return floor + 'nd';
            case 3:
                return floor + 'rd';
            default:
                return floor + 'th';
        }
    },

    getTimeDeal: (time) => {
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let d = new Date(time)
        let curr_date = d.getDate()
        let curr_month = d.getMonth()
        let curr_year = d.getFullYear()

        curr_date = (curr_date < 10) ? '0' + curr_date : curr_date
        return `${curr_date} ${monthNames[curr_month]} ${curr_year}`
    },

    formartDateTime: (time) => {
        let d = new Date(time);
        let curr_date = d.getDate();
        let curr_month = d.getMonth() + 1;
        let curr_year = d.getFullYear();
        let curr_hours = d.getHours();
        let curr_minus = d.getMinutes();
        let curr_second = d.getSeconds();

        curr_minus = (curr_minus < 10) ? '0' + curr_minus : curr_minus;
        curr_hours = (curr_hours < 10) ? '0' + curr_hours : curr_hours;
        curr_date = (curr_date < 10) ? '0' + curr_date : curr_date;
        curr_month = (curr_month < 10) ? '0' + curr_month : curr_month;

        return curr_hours + ':' + curr_minus + '      ' + curr_date + "/" + curr_month + "/" + curr_year;
    },

    convertDate: (time) => {
        try {
            if (!time) {
                return ''
            }

            let d = new Date(time);
            let curr_date = d.getDate();
            let curr_month = d.getMonth() + 1;
            let curr_year = d.getFullYear();

            if (isNaN(curr_date) || isNaN(curr_month) || isNaN(curr_year)) {
                return time
            }

            curr_date = (curr_date < 10) ? '0' + curr_date : curr_date;
            curr_month = (curr_month < 10) ? '0' + curr_month : curr_month;

            return curr_date + "/" + curr_month + "/" + curr_year ;

        } catch (err) {
            return time
        }
    },

    getCompanyType: (company_name) => {
        company_name = company_name.toLowerCase()
        if (company_name.indexOf('pte ltd'))
            return 'PL'

        if (company_name.indexOf('sole proprietor'))
            return 'SP'

        if (company_name.indexOf('limited liability'))
            return 'LP'

        return ''
    },

    getDate: (date_time) => {
        let date_of_regis = date_time.split('/')
        let day = date_of_regis[0]
        let month = date_of_regis[1] || ''
        let year = date_of_regis[2] ? date_of_regis[2].slice(-2) : ''

        return `${day}${month}${year}`
    },

    convertSerialNoContractor: (contractor) => {
        let { vendor_contractor_detail_id } = contractor
        if (!vendor_contractor_detail_id) return ''
        let { date_of_regis, company_name } = vendor_contractor_detail_id
        // let date = self.getDate(date_of_regis)
        // let company = self.getCompanyType(company_name)

        // return `CA${date}${company}01`
        return company_name
    },

    convertSerialNoDesigner: (designer) => {
        let { vendor_designer_detail_id } = designer
        if (!vendor_designer_detail_id) return ''
        let { date_of_regis, nationality, name_of_designer } = vendor_designer_detail_id
        // let date = self.getDate(date_of_regis)
        // let company = nationality ? 'S' : 'NS'

        // return `DS${date}${company}01`
        return name_of_designer
    },

    convertSerialNoPM: (pm) => {
        let { vendor_pm_detail_id } = pm
        if (!vendor_pm_detail_id) return ''
        let { date_of_regis, nationality, name_of_project_manager } = vendor_pm_detail_id
        // let date = self.getDate(date_of_regis)
        // let company = nationality ? 'S' : 'NS'

        // return `PM${date}${company}01`
        return name_of_project_manager
    },

    calculatorPriceItem: (priceItemEdit = {}, priceItemContractor, items, area_items_id, quote_room_id) => {
        let item_id = items._id
        let key = `${area_items_id}_${quote_room_id}`
        let priceItem = priceItemEdit[key] ? priceItemEdit[key] : (priceItemContractor[item_id] ? priceItemContractor[item_id] : { price: items.price, type_item: 0 });
        return priceItem
    },

    calculatorPriceItemNotType: (priceItemEdit = {}, priceItemContractor, items, area_items_id, quote_room_id) => {
        let item_id = items._id
        let key = `${area_items_id}_${quote_room_id}`
        let priceItem = priceItemEdit[key] ? priceItemEdit[key] : (priceItemContractor[item_id] ? priceItemContractor[item_id] : items.price);
        return priceItem
    },

    calculatorAreaItem: (itemData, quoteRoom) => {
        let { width, height, length, floor_area, unit } = quoteRoom
        let { area_items, items, value, type_value, quantity } = itemData

        if (items.type_price == 'lot') {
            if (value && type_value == 'lot') {
                return {
                    quantity: parseInt(value) * quantity,
                    type: 'lot'
                }
            } else {
                return {
                    quantity,
                    type: 'lot'
                }
            }
        } else {
            if (value && type_value != 'lot') {
                return {
                    quantity: value,
                    type: type_value
                }
            } else {
                let {input_type} = area_items;
                width = self.convertToFt(width, unit);
                height = self.convertToFt(height, unit);
                length = self.convertToFt(length, unit);
                floor_area = floor_area ? self.convertToSqf(floor_area, unit) : 0

                let areaItems = self.calculatorArea(width, height, length, input_type, floor_area);
                areaItems = quantity * areaItems
                return {
                    quantity: parseFloat(areaItems).toFixed(2),
                    type: items.type_price
                }
            }
        }
    },

    reduceInfo: (text) => {
        if (text.length > 225) {
            let index = 220
            while (index <= 225) {
                if (text[index] == `.` || text[index] == ` `) break
                index++
            }
            return text.substring(0, index) + ' ...'
        }
        return text
    },

    convertQuoteId : (quote, customer) => {
        let { updated_at } = quote
        let d = new Date(updated_at);
        let curr_date = d.getDate();
        let curr_month = d.getMonth() + 1;
        let curr_year = d.getFullYear();
        let curr_hours = d.getHours();
        let curr_minus = d.getMinutes();
        let curr_second = d.getSeconds();

        curr_minus = (curr_minus < 10) ? '0' + curr_minus : curr_minus;
        curr_hours = (curr_hours < 10) ? '0' + curr_hours : curr_hours;
        curr_date = (curr_date < 10) ? '0' + curr_date : curr_date;
        curr_month = (curr_month < 10) ? '0' + curr_month : curr_month;

        return `ruhu/${customer.first_name}${customer.last_name}/${curr_hours}${curr_minus}/${curr_date}${curr_month}/${curr_year}`
    },
    getUrlParameter: (str, name) => {
        if (!str) {
            return '';
        }
    
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(str);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    },
}