const initialState = {
    'home': '/',
    'about-us': '/about-us',
    'contractors': '/contractors',
    'contact-us': '/contact-us',
    'designers': '/designers',
    'project-manager': '/project-manager',
    'suppliers': '/suppliers',
    'privacy-policy': '/privacy-policy',
    'term-conditions': '/term-conditions',
    'faq': 'faq'
}

export default function page_seo(state = initialState, action) {
    switch (action.type) {
        case 'SET_PAGE_SEO': {
            return Object.assign({}, action.data)
        }
        default: return state
    }
}