const initialState = {
    save_quote: false,
    quote_id: ''
}

export default function info(state = initialState, action) {
    switch (action.type) {
        case 'SET_SAVE_QUOTE': {
            return {
                ...state,
                save_quote: action.data.save_quote,
                quote_id: action.data.quote_id
            }
        }
        
        default: return state
    }
}