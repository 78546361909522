import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import { setCustomer, setPageSeo } from '../../actions/index'
import { Cookies } from 'react-cookie'
import { getUrlParameter } from '../../utils/convert'
import { get_customer_by_token, page_seo_key, create_quote } from '../../query'

const cookies = new Cookies()

class Header extends Component {

    componentWillMount() {
        let {dispatch} = this.props;
        page_seo_key({})
            .then(data => {
                if (data.signal) {
                    dispatch(setPageSeo(data.data));
                }
            })
            .catch(err => console.log(err));
    }

    componentDidMount() {
        let { dispatch } = this.props
        let search = window.location.search
        let token = cookies.get('customer')
        let isTokeFb = getUrlParameter(search, 'token')
        token = token || isTokeFb
        let q_id = getUrlParameter(search, 'q')

        if (token) {
            get_customer_by_token({ token })
            .then(data => {
                if (data.signal) {
                    dispatch(setCustomer(data.data.customer))
                    cookies.set('customer', token, { path: '/', maxAge: 96969696 })
                    if (isTokeFb) {
                        setTimeout(() => {
                            window.location.href = (q_id && q_id != 'undefined') ? q_id : "/profile"
                        }, 500)
                    }
                   
                } else {
                    cookies.remove('customer', {path : '/', maxAge: 96969696})
                }
            })
            .catch(err => console.log(err))
        }
    }

    logoutVendor(e) {
        e.preventDefault();
        let {dispatch} = this.props;  
        cookies.remove('customer', {path : '/', maxAge: 96969696})
        dispatch(setCustomer({}))
    }

    getQuote = (e) => {
        e.preventDefault()
        let { customer } = this.props
        let ele = document.getElementsByClassName('btn-quick-home'); 
        if (ele[0]) {
            ele[0].click()
        } else {
            let params = {
                image: [],
                areas: '300-600sqf',
                number_of_bedroom: 1,
                state: 'New',
                house_type: 'HDB',
                step: 2,
                status: 'draft'
            };
    
            if (customer._id)
                params.customer = customer._id;
    
                create_quote(params)
                .then(resp => {
                    window.location.href = '/quotes/room/' + resp.data._id
                })
                .catch(err => console.log(err))
        }
    }

    renderSignIn() {
        let {customer, dispatch} = this.props;
        if (customer._id) {
            return (
                <li>
                    <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="lang fontSemiBold">
                        <img src="/images/user-logo.png" alt="Ruhu" className="avatar mr-5" />
                        {`Hello ${customer.last_name}`}
                        <span className="fa fa-angle-down ml-5"></span>
                    </a>
                    
                    <ul className="dropdown-menu dropdown-menu-right">
                        <li><a href="/profile">My profile</a></li>
                        <li><a href="/my-quotes">My quotes</a></li>
                        <li><a href="#" onClick={this.logoutVendor.bind(this)}>Logout</a></li>
                    </ul>
                </li>
            );
        } else {
            return (
                <Fragment>
                    <li>
                        <a href="#" className="h-signin" data-toggle="modal" data-target="#modal-sign-in" data-backdrop="static" data-keyboard="false"><img src="/images/logo-signin.png" alt="Ruhu" className="ico" />Signin</a>
                    </li>

                    <li>
                        <a href="/register" className="h-signin">Sign up</a>
                    </li>
                </Fragment>
            );
        }
    }

    render() {
        let {customer, dispatch, pageSeo} = this.props;
        pageSeo = pageSeo || {}
        return (
            <div className="header">
                <nav className="navbar navbar-default h-main">
                    <div className="container">

                        <div className="navbar-header">
                            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            </button>
                            <a href={`/${pageSeo['home'] || ''}`} className="navbar-brand">
                                <img src="/images/new/ruhu-logo-13.png" alt="HOME" style={{width: '150px'}}/>
                            </a>
                        </div>

                        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                            <ul className="nav navbar-nav h-main-menu">
                                <li><a href={`/${pageSeo['home'] || ''}`}>Home</a></li>
                                <li><a href={`/${pageSeo['about-us'] || 'about-us'}`}>About</a></li>
                                <li><a href={`/${pageSeo['contractors'] || 'contractors'}`}>Contractor</a></li>
                                <li><a href={`/${pageSeo['designers'] || 'designers'}`}>Designer</a></li>
                                <li><a href={`/${pageSeo['project-manager'] || 'project-manager'}`}>Project manager</a></li>
                                <li><a href={`/${pageSeo.supplier || 'supplier'}`}>Suppliers</a></li>
                            </ul>
                            <ul className="nav navbar-nav navbar-right">
                                <li>
                                    <a href="#" className="bg-blue h-getquote" onClick={this.getQuote}>
                                        Get Quote
                                    </a>
                                </li>
                                <li>
                                    <a href="https://api.whatsapp.com/send?phone=6588695179" target="_blank" className="bg-blue h-getphone">
                                        {/* <img src="/images/phone.jpg" alt="Ruhu" /> */}
                                        <img src="/images/hotline.png" className="icon-image mr-2" alt="Ruhu"/>
                                        +65 63980900
                                    </a>
                                </li>
                            </ul>
                        </div>
                        
                    </div>
                </nav>
            </div> 
        );
    }
}

function mapStateToProps(state) {
    const {customer, pageSeo} = state
    return { customer, pageSeo }
}

export default connect(mapStateToProps)(Header)