import React, { Component } from 'react'

import { Provider } from 'react-redux'
import {configureStore} from './store';
import AppRoutes from './router/home'
const store = configureStore({});

class AppHome extends Component {
  render() {
    return (
      <Provider store={store}>
        <AppRoutes />
      </Provider>
    )
  }
}

export default AppHome
