module.exports = {
    BASE_URL_ADMIN: process.env.API_URL || 'http://localhost:6969',
    AREA_OPTION: {
        'HDB': [
            '300-600sqf',
            '600-800sqf',
            '800-1000sqf',
            '1000-1200sqf',
            '1200-1400sqf',
            '1400-1600sqf',
            '1600-1800sqf'
        ],
        'Condo': [
            '300-600sqf',
            '600-800sqf',
            '800-1000sqf',
            '1000-1200sqf',
            '1200-1400sqf',
            '1400-1600sqf',
            '1600-1800sqf',
            '1800-2000sqf',
            '2000-2200sqf',
            '2200-2400sqf',
            '2400-2600sqf',
            '2600-2800sqf',
            '2800-3000sqf',
            '3000-3200sqf',
            '3200-3400sqf',
            '3400-3600sqf'
        ],
        'Landed': [
            '1600-1800sqf',
            '1800-2000sqf',
            '2000-2200sqf',
            '2200-2400sqf',
            '2400-2600sqf',
            '2600-2800sqf',
            '2800-3000sqf',
            '3000-3200sqf',
            '3200-3400sqf',
            '3400-3600sqf',
            '3600-3800sqf',
            '3800-4000sqf',
            '4000-4200sqf',
            '4200-4400sqf',
            '4400-4600sqf',
            '4600-4800sqf',
            '4800-5000sqf'
        ],
        'Commercial' : [
            '3000-3200sqf',
            '3200-3400sqf',
            '3400-3600sqf',
            '3600-3800sqf',
            '3800-4000sqf',
            '4000-4200sqf',
            '4200-4400sqf',
            '4400-4600sqf',
            '4600-4800sqf',
            '4800-5000sqf',
            '5000-5200sqf',
            '5200-5400sqf',
            '5400-5600sqf',
            '5600-5800sqf',
            '5800-5000sqf',
            '6000-6200sqf',
            '6200-6400sqf',
            '6400-6600sqf',
            '6600-6800sqf',
            '6800-7000sqf'
        ] 
    },
    NUMBER_YEAR_OPERATION: [
        '<1 yrs',
        '1 to 2 yrs',
        '2 to 3 yrs',
        '3 to 4 yrs',
        '4 to 5 yrs',
        '5 to 6 yrs',
        '6 to 7 yrs',
        '7 to 8 yrs',
        '8 to 9 yrs',
        '9 to 10 yrs',
        '10 to 15 yrs',
        '15 to 22 yrs',
        '20 to 25 yrs',
        '25 to 30 yrs',
        '>30 yrs'
    ],
    NUMBER_YEAR_PRACTIVE: [
        '1 to 2 yrs',
        '2 to 3 yrs',
        '3 to 4 yrs',
        '4 to 5 yrs',
        '5 to 6 yrs',
        '6 to 7 yrs',
        '7 to 8 yrs',
        '8 to 9 yrs',
        '9 to 10 yrs',
        '10 to 15 yrs',
        '15 to 22 yrs',
        '20 to 25 yrs',
        '25 to 30 yrs',
        '>30 yrs'
    ],
    PAID_UP_CAPITAL: [
        '<$10000',
        '$10000 to $25000',
        '$25000 to $50000',
        '$50000 to $75000',
        '$75000 to $100000',
        '$10000 to $125000',
        '$125000 to $150000',
        '$150000 to $175000',
        '$175000 to $200000',
        '$200000 to $225000',
        '$225000 to $250000',
        '$250000 to $275000',
        '$275000 to $300000',
        '$300000 to $350000',
        '$350000 to $400000',
        '$400000 to $450000',
        '$450000 to $500000',
        '>$500000'
    ],
    YEAR_OF_WARRANTY: [
        '0 yrs',
        '1/2 yrs',
        '1 yrs',
        '2 yrs',
        '3 yrs',
        '4 yrs',
        '5 yrs',
        '6 yrs',
        '7 yrs',
        '8 yrs',
        '9 yrs',
        '10 yrs'
    ]
}