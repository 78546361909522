import React, { Component, Fragment } from 'react';
import { get_customer_by_token, create_quote, upload_image } from '../../query'
import { BASE_URL_ADMIN, AREA_OPTION } from '../../data/config';
// import ModalEnquiry from '../Modal/ModalEnquiry'
import { Cookies } from 'react-cookie'

const cookies = new Cookies()

class BoxQuoteNewHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            areas: AREA_OPTION.HDB,
            quote: {
                areas: AREA_OPTION.HDB[0],
                number_of_bedroom: 1,
                state: 'New',
                house_type: 'HDB'
            },
            image: [],
            houseType: [
                {
                    img: '/images/house-type1.png',
                    name: 'HDB'
                },
                {
                    img: '/images/house-type2.png',
                    name: 'Condo'
                },
                {
                    img: '/images/house-type3.png',
                    name: 'Landed'
                },
                {
                    img: '/images/house-type4.png',
                    name: 'Commercial'
                }
            ],
            imageIndex: 0,
            customer: {}
        }
    }

    componentDidMount(){
        const token = cookies.get('customer')
        if (token) get_customer_by_token({ token })
            .then(data => {
                if (data.signal) {
                    this.setState({
                        customer: data.data.customer
                    })
                } else {
                    cookies.remove('customer', {path : '/', maxAge: 96969696})
                }
            })
            .catch(err => console.log(err))
    }

    submit = (e) => {
        e.preventDefault();
        let { quote, image, customer } = this.state;

        if (image.length > 20) {
            return alert('Please attract up maximum 20 images')
        }

        let params = {
            image,
            areas: quote.areas,
            number_of_bedroom: quote.number_of_bedroom,
            state: quote.state,
            house_type: quote.house_type,
            step: 2,
            status: 'draft'
        };

        if (customer._id)
            params.customer = customer._id;

        create_quote(params)
            .then(resp => {
                window.location.href = '/quotes/room/' + resp.data._id
            })
            .catch(err => console.log(err))
    }

    renderFileLabel = () => {
        return 'Attach up to 20 images'
    }

    selectQuoteProperty = (property, e) => {
        let { quote } = this.state;
        quote[property] = e.target.value;
        this.setState({ quote });
    }

    selectFile = (event) => {
        const files = event.target.files
        let nb_img = this.state.image.length
        let total_img = nb_img + files.length
        if (total_img > 20) {
            return alert('Please attract up maximum 20 images')
        }

        for (let i = 0; i < files.length; i++) {
            let params = new FormData()
            params.append('image', files[i])
            upload_image(params)
                .then(resp => {
                    let url = BASE_URL_ADMIN + resp.data.fileUrl;
                    this.setState(state => ({
                        image: state.image.concat([url])
                    }));
                })
                .catch(err => console.log(err))
        }
    }

    selectTypeHouse(name, e) {
        e.preventDefault();
        let { quote } = this.state;
        quote.house_type = name;
        let areaOpt = AREA_OPTION[name];
        quote.areas = areaOpt[0];

        this.setState({
            quote,
            areas: areaOpt
        });
    }

    renderHouseType = (types) => types.map((item, index) =>
        <li key={`house_${item.name}`} className={(this.state.quote.house_type === item.name) ? 'active' : null} role="button" onClick={this.selectTypeHouse.bind(this, item.name)}>
            <div><img src={item.img} alt={item.name} alt="Ruhu"/></div>
            <span>{item.name}</span>
        </li>
    )

    renderOptions = (options) => options.map((item, index) => <option value={item} key={`area_${index}`}>{item}</option>)

    renderThumbnail(source) {
        let { imageIndex } = this.state
        let content = source.map((item, idx) => {
            if (idx < imageIndex || idx > (imageIndex + 3)) return ''
            return (
                <li key={`image_${item}`}>
                    <a href="#"><img className="thumbnail-quote" src={`${item}`} alt="Ruhu" /></a>
                    <div className="last-down" onClick={this.removeImage.bind(this, idx)}>
                        <div><span className="fa fa-trash icon"></span></div>
                        Remove
                    </div>
                </li>
            )
        })

        if (source.length > 4) {
            return (
                <Fragment>
                    {(imageIndex > 0) && <span className="fa fa-arrow-circle-left" role="button" onClick={this.nextImage.bind(this, imageIndex - 1)}></span>}
                    {content}
                    {(imageIndex + 4 < source.length) && <span className="fa fa-arrow-circle-right" role="button" onClick={this.nextImage.bind(this, imageIndex + 1)}></span>}
                </Fragment>
            )
        } else {
            return content
        }
    }

    nextImage(idx) {
        this.setState({
            imageIndex: idx
        })
    }

    removeImage(idx) {
        let isAccept = window.confirm("Do you want remove this image!");
        if (isAccept == true) {
            let { image } = this.state
            image = image.filter((img, index) => {
                return index != idx
            })

            this.setState({
                image
            })
        }
    }

    render() {
        return (
            <div className="block-house-type">
                <div className="title">Your house type</div>
                <ul className="list-unstyled clearfix list-house">
                    {(this.state.houseType.length) ? this.renderHouseType(this.state.houseType) : null}
                </ul>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="sub-title">Areas to renovate</div>
                        <div className="form-group">
                            <select className="form-control form-control-mix" value={this.state.quote.areas} onChange={this.selectQuoteProperty.bind(this, 'areas')}>
                                {(this.state.areas.length) ? this.renderOptions(this.state.areas) : null}
                            </select>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="sub-title">New or Resale</div>
                        <div className="form-group">
                            <select className="form-control form-control-mix" value={this.state.quote.state} onChange={this.selectQuoteProperty.bind(this, 'state')}>
                                {this.renderOptions(['New', 'Resale', 'Current', 'Rental'])}
                            </select>
                        </div>
                    </div>
                </div>

                <div className="title">Submit Floorplan/Pictures (Optional)</div>
                <div className="ipn-file">
                    <input accept="image/*" onChange={this.selectFile.bind(this)} name="file" id="file" type="file" className="inputfile" data-multiple-caption="{count} files selected" multiple />
                    <label htmlFor="file">
                        <span>{this.renderFileLabel()}</span>
                    </label>
                </div>
                {(this.state.image.length) ? <ul className="list-inline list-img list-download">{this.renderThumbnail(this.state.image)}</ul> : null}
                <a href="#" className={(this.props.button) ? this.props.button : "continue bg-blue btn-quick-home"} onClick={this.submit.bind(this)}>Get Immediate Quote with Live-Bidding</a>
                <a href="#" data-toggle="modal" data-target={this.state.customer._id != null ? '#form-enquiry' : '#form-enquiry'} className={(this.props.button) ? this.props.button : "continue bg-blue"}>
                    <span className="">Get Free Design Consultation</span>
                </a>

                {/* <ModalEnquiry customer={this.state.customer}/> */}

            </div>
        );
    }
}

export default BoxQuoteNewHome;