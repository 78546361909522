import React from 'react'
import MainNoAds from '../components/MainNoAds/Home'

const AppRouteAds = ({ component: Component, layout: Layout, ...rest }) => (
    <Route {...rest} render={props => (
        <Layout>
            <Component {...props} />
        </Layout>
    )} />
)

export default () => {
    return (
        <MainNoAds>
            <div></div>
        </MainNoAds>
    )
}