import React, { Component, Fragment } from 'react';
import { validateEmail } from '../../utils/validate'
import { BASE_URL_ADMIN } from '../../data/config'
import VerifyEmail from '../Register/VerifyEmail'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { connect } from 'react-redux'
import { register_customer } from '../../query'
import ModalColLeft from '../Common/ModalColLeft'

class ModalRegister extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            mobile: '',
            password: '',
            code: '+65',
            check_policy: false,
            success: false
        }
    }

    handleInput = (key, e) => {
        this.setState({
            [key]: e.target.value
        })
    }

    submitRegister = (e) => {
        e.preventDefault()

        let { password, email, mobile, code, check_policy } = this.state

        if (!check_policy) {
            return alert('Please read and agree to the Terms and Conditions and Privacy Policy')
        }

        if (!password || !email || !mobile )
            return alert('Please fill all information register')

        if (!validateEmail(email))
            return alert('Please enter a valid email')

        if (!code) {
            return alert('Please enter a valid mobile number')
        }

        code = code.replace(/\+/g, "")
        mobile = `${code}${mobile}`

        if (!isValidPhoneNumber(`+${mobile}`)) {
            return alert('Please enter a valid mobile number')
        }

        register_customer({ password, email, phone: mobile })
            .then(result => {
                if (result.signal) {
                    this.setState({
                        success: true
                    })
                } else {
                    alert(result.message)
                }
            })
            .catch(err => console.log(err))
    }

    componentDidMount() {
        
        this.initJquery();
    }


    handleCheckChange = (e) => {
        this.setState({
            'check_policy' : e.target.checked
        });
    }

    initJquery() {
        let self = this;
        $(document).ready(function($) {
            $("#modal-regis-free").click(function(ev){
                if(ev.target != this) return;
                $('#modal-regis-free').modal('hide');
            });
        });                
    }

    render() {
        let { pageSeo } = this.props
        pageSeo = pageSeo || {}
        let { email, password, mobile, success, code } = this.state

        code = code.replace(/\+/g, "")
        let phone = `${code}${mobile}`

        return (
            <Fragment>
                <div className="modal fade" id="modal-regis-free" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="true">
                    <div className="modal-dialog modal-dialog-regis-free" role="document">
                        <div className="modal-regis-free">
                            <ModalColLeft />
                            <div className="col-right">
                                <div className="title text-center">Sign Up for a Free Membership</div>
                                <div className="sub-title text-center">Create Your Account. It's Free!</div>

                                { !success ? (
                                    <form action="" onSubmit={this.submitRegister}>
                                        <div className="form-regis-free">
                                            <div className="form-group">
                                                <input type="email" placeholder="Email" name="email_regis_free" value={email} onChange={this.handleInput.bind(this, 'email')} className="form-control" required/>
                                            </div>
                                            <div className="form-group">
                                                <input type="password" placeholder="Password" name="password_regis_free" value={password} onChange={this.handleInput.bind(this, 'password')} required className="form-control"/>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <input type="text" placeholder="Code" name="code" required className="form-control" value={code} onChange={this.handleInput.bind(this, 'code')} />
                                                </div>
                                                <div className="col-md-9">
                                                    <input type="text" placeholder="Phone number" name="contact_regis_free" required className="form-control" value={mobile} onChange={this.handleInput.bind(this, 'mobile')} />
                                                </div>
                                            </div>
                                            <div className="list-check">
                                                <div className="item-checkbox">
                                                    <input id="check_remember" type="checkbox" name="check_remember"/>
                                                    <label htmlFor="check_remember">Remember me</label>
                                                </div>
                                                <a href="#">Forgot your password</a>
                                            </div>
                                            <button type="submit" className="submit-form-regis">Register Now!</button>
                                            <div className="sign-up-other">
                                                <div className="text-center title">or Sign Up Using</div>
                                                <div className="list-sign-up">
                                                    <a href={`${BASE_URL_ADMIN}/auth/facebook`} className="facebook"><img src={'/images/facebook.png'} alt="Ruhu"/></a>
                                                    <a href={`${BASE_URL_ADMIN}/auth/google`} className="google"><img src={'/images/google-plus.png'} alt="Ruhu"/></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item-checkbox term">
                                            <input id="check_term" type="checkbox" name="check_term" checked={this.state.check_policy} onChange={this.handleCheckChange} />
                                            <label htmlFor="check_term">Please indicate that you have read and agree to the <a href={`/${pageSeo['term-conditions'] || 'term-conditions'}`} target="_blank">Terms and Conditions</a> and <a href={`/${pageSeo['privacy-policy'] || 'privacy-policy'}`} target="_blank">Privacy Policy</a></label>
                                        </div>
                                    </form>
                                ) : (
                                    <div>
                                        <VerifyEmail mobile={phone} />
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { pageSeo } = state
    return { pageSeo }
}

export default connect(mapStateToProps)(ModalRegister)