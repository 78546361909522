import axios from 'axios'

export default function makeRequest (method, url, params = {}, header = {}, timeout = 3000) {
    return new Promise((resolve, reject) => {
        method = method.toLowerCase()
        let opts = {
            method: method,
            url: url
        }

        if (header) {
            opts.headers = header;
        }

        if (method === 'get')
            opts.params = params;
        else
            opts.data = params;

        axios(opts).then(response => {
            resolve(response.data);
        }).catch(err => {
            console.log('ERROR_REQUEST', err);
            reject('Something went wrong!');
        })
    })
}