import makeRequest from './request';
import {
    BASE_URL_ADMIN
} from './data/config';

function get_room_types(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/roomType/getRoomType`, params)
}

function upload_image(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/upload-image`, params, { 'Content-Type': 'multipart/form-data' })
}

function create_quote(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/quote/new`, params)
}

function update_quote(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/quote/update`, params)
}

function get_quote_by_id(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/quote/get`, params)
}

function get_area_of_room_type(params) {
	return makeRequest('get', `${BASE_URL_ADMIN}/api/roomType/getAreaOfRoom`, params);
}

function create_quote_room(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/quote/addRoom`, params)
}

function select_multi_area(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/quote/selectArea`, params)
}

function edit_item_quote(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/quote/editItem`, params)
}

function create_quote_room_v2(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/quote/addRoomV2`, params)
}

function remove_quote_room(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/quote/removeRoom`, params)
}

function remove_quote_room_area(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/quote/removeQuotesRoomArea`, params)
}

function get_otp_consultation(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/customer/getOTPConsultation`, params)
}

function verifyOTPConsultation(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/customer/verifyOTPConsultation`, params)
}

function update_quote_room(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/quote/updateRoom`, params)
}

function get_quote_room(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/quote/getQuotesRoom`, params);
}

function get_child_node_area(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/roomType/getChildNodeOfArea`, params);
}

function get_child_node_area_template(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/roomType/getChildNodeOfAreaTemplate`, params);
}

function get_items_by_child_node(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/roomType/getItemOfChildNode`, params);
}

function get_log_items_by_child_node(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/roomType/getLogItemOfChildNode`, params);
}

function select_items_area_work(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/quote/selectItems`, params)
}

function select_items_area_work_v2(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/quote/selectItemsV2`, params)
}

function select_contractor_quotes(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/quote/selectContractor`, params)
}

function select_designer_quotes(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/quote/selectDesigner`, params)
}

function select_pm_quotes(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/quote/selectPm`, params)
}

function get_all_group(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/roomType/getGroup`, params);
}

function get_all_group_slug(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/roomType/getGroupWithSlug`, params);
}

function get_designer_pm_quotes(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/quote/getDesignerPm`, params);
}

function get_contractor_quotes(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/quote/getContractor`, params);
}

function get_post_contractor_quotes(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/quote/getContractor`, params);
}

function count_post_contractor_quotes(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/quote/getTotalContractor`, params);
}

function get_contractor_by_group(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/vendor/getContractorPagination`, params);
}

function get_contractor_by_group_slug(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/vendor/getContractorSlugPagination`, params);
}

function count_ds_pm(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/vendor/getTotalDsPm`, params);
}

function sort_contractor_by_group(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/vendor/sortContractorPagination`, params);
}

function get_contractor_select_by_group(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/vendor/getContractorForSelect`, params);
}

function get_designer(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/vendor/getDesigner`, params);
}

function get_designer_pagination(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/vendor/getDesignerPagination`, params);
}

function get_pm_pagination(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/vendor/getPmPagination`, params);
}

function get_pm(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/vendor/getPm`, params);
}

function get_items_of_quotes(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/quote/getItemOfQuotes`, params);
}

function get_info_confirm_quotes(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/quote/getInfoConfirm`, params);
}

function customer_sign_in(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/customer/login`, params);
}

function get_customer_by_token(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/customer/verify`, params);
}

function get_quotes_of_customer(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/quote/getQuotesOfCustomer`, params);
}

function get_contractor_detail(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/vendor/getContractorDetail`, params)
}

function get_designer_detail(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/vendor/getDesignerDetail`, params)
}

function get_pm_detail(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/vendor/getPMDetail`, params)
}

function get_total_quote_detail(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/quote/getInfoQuotes`, params);
}

function get_contractor_quote_detail(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/quote/getQuotesContractor`, params);
}

function get_designer_quote_detail(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/vendor/getDetailQuoteDesigner`, params);
}

function get_pm_quote_price(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/vendor/getPmQuoteInfo`, params);
}

function confirm_quote_finally(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/quote/confirmQuote`, params)
}

function get_room_sub_category(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/quote/getSubcategorySave`, params);
}

function get_room_child_select(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/quote/getRoomChildSelect`, params);
}

function get_last_deals(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/project/lastDeal`, params);
}

function get_latest_project(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/setting/home/project`, params);
}

function get_home_vendor(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/setting/home/vendor`, params);
}

function reset_password_verify(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/customer/checkReset`, params);
}

function get_link_social(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/setting/social`, params);
}

function get_advert_position(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/setting/advert`, params);
}

function customer_reset_password(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/customer/resetPassword`, params)
}

function update_profile(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/customer/update`, params)
}

function customer_approve_quote(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/quote/customerApprove`, params);
}

function start_project(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/quote/startProject`, params)
}

function cancel_project(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/quote/cancelProject`, params)
}

function send_mail_contact(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/customer/mail-contact`, params)
}

function register_customer(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/customer/register`, params)
}

function verify_mobile(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/customer/verifyMobile`, params)
}

function register_verify(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/customer/verify`, params)
}

function customer_forgot_password(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/customer/forgot-password`, params)
}

function get_homepage_video(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/setting/video`, params)
}

function get_all_template(params = {}) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/roomType/getTemplate`, params)
}

function get_item_quote_group(params = {}) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/quote/getItemGroup`, params)
}
function blogs(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/blog`, params)
}
function blog_detail(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/detail/`, params);
}

function post_detail(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/posts/postDetail`, params);
}

function vendor_detail(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/posts/vendorDetail`, params);
}

function page_seo_detail(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/posts/pageSeo`, params);
}

function page_seo_key(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/posts/allPageSeo`, params);
}

function get_post_slug(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/posts/getPostSlug`, params);
}

function get_page_slug(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/posts/getPageSlug`, params);
}

function get_seo_page_key(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/posts/getPostPageKey`, params);
}

// reveiw
function reveiw_contractor(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/contractor/review`, params)
}
function rating_contractor(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/contractor/rating`, params)
}
function get_list_contractor_review(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/contractor/review`, params)
}
function get_last_review(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/quote/last-review`, params)
}
function get_supplier(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/supplier/list`, params)
}
function get_suppliers_by_category(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/supplier/by-category`, params)
}
function get_supplier_categories(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/supplier/categories`, params)
}
function get_supplier_detail(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/supplier/detail`, params)
}
function get_topchoices(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/supplier/topchoice`, params)
}
function get_list_gallery(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/supplier/gallery`, params)   
}
function get_execlusive_photo(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/supplier/exclusive/detail`, params)   
}
function save_enquiry(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/supplier/enquiry/add`, params)
}

function save_consultation(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/customer/consultation`, params)
}

function get_vouchers_by_quote(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/supplier/vouchers/list`, params)   
}
function save_voucher(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/supplier/voucher/add`, params)
}
function send_enquiry(params) {
    return makeRequest('post', `${BASE_URL_ADMIN}/api/supplier/enquiry/add`, params)
}

function get_vendor_detail(params) {
    return makeRequest('get', `${BASE_URL_ADMIN}/api/vendor/getDetail`, params)
}

module.exports = {
    verifyOTPConsultation,
    get_otp_consultation,
    select_multi_area,
    get_room_types,
    cancel_project,
    upload_image,
    create_quote,
    update_quote,
    get_quote_by_id,
    get_area_of_room_type,
    create_quote_room,
    create_quote_room_v2,
    remove_quote_room,
    remove_quote_room_area,
    update_quote_room,
    get_child_node_area,
    get_child_node_area_template,
    edit_item_quote,
    get_quote_room,
    get_items_by_child_node,
    get_log_items_by_child_node,
    select_items_area_work,
    select_items_area_work_v2,
    select_contractor_quotes,
    select_pm_quotes,
    select_designer_quotes,
    get_all_group,
    get_all_group_slug,
    get_contractor_by_group,
    get_contractor_by_group_slug,
    sort_contractor_by_group,
    get_contractor_select_by_group,
    get_contractor_quotes,
    get_post_contractor_quotes,
    count_post_contractor_quotes,
    count_ds_pm,
    get_designer_pm_quotes,
    get_designer,
    get_designer_pagination,
    get_contractor_detail,
    get_designer_detail,
    get_pm,
    get_items_of_quotes,
    get_info_confirm_quotes,
    customer_sign_in,
    get_customer_by_token,
    get_quotes_of_customer,
    get_total_quote_detail,
    get_contractor_quote_detail,
    get_designer_quote_detail,
    get_pm_quote_price,
    customer_approve_quote,
    confirm_quote_finally,
    get_room_child_select,
    get_room_sub_category,
    update_profile,
    start_project,
    send_mail_contact,
    register_customer,
    verify_mobile,
    customer_forgot_password,
    register_verify,
    get_last_deals,
    reset_password_verify,
    customer_reset_password,
    get_pm_pagination,
    get_pm_detail,
    get_link_social,
    get_advert_position,
    get_homepage_video,
    get_all_template,
    get_item_quote_group,
    blogs,
    blog_detail,
    reveiw_contractor,
    rating_contractor,
    get_list_contractor_review,
    get_last_review,
    get_supplier,
    get_suppliers_by_category,
    get_supplier_categories,
    get_supplier_detail,
    save_enquiry,
    save_consultation,
    get_topchoices,
    get_list_gallery,
    get_execlusive_photo,
    get_vouchers_by_quote,
    save_voucher,
    send_enquiry,
    get_home_vendor,
    get_latest_project,
    get_vendor_detail,

    //seo api
    post_detail,
    vendor_detail,
    page_seo_detail,
    page_seo_key,
    get_post_slug,
    get_page_slug,
    get_seo_page_key
}