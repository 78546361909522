import React, { Component } from 'react';
import { verify_mobile } from '../../query'

class VerifyMobile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            code: '',
            success: false
        }
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    verify = (e) => {
        e.preventDefault()
        let {code} = this.state
        let {mobile} = this.props
        if (!code || !mobile)
            return alert('Please enter code')
        
        verify_mobile({code, mobile} )
            .then(result => {
                if (result.signal) {
                    this.setState({
                        success: true
                    })
                } else {
                    alert(result.message)
                }
            })
            .catch(err => console.log(err))
    }

    showSignIn = (e) => {
        e.preventDefault()

        $('#modal-regis-free').modal('hide')
        $('#modal-sign-in').modal('show')
    }

    render() {
        let {success} = this.state
        if (!success) {
            return (
                <form onSubmit={this.verify.bind(this)}>
                    <div className="form-group text-primary text-center" style={{fontSize: "16px"}}>
                        Register account customer successfully
                    </div>
                    <div className="text-primary">
                        You should soon receive an email allowing you to active account. 
                    </div>
                    <div className="form-group text-primary">
                        We also send a verification code to your phone number. You can enter the code to active account.
                    </div>
                    <div className="">
                        <div className="">
                            <div className="form-group">
                                <input type="text" className="form-control form-control-mix" placeholder="Enter code" name="code" value={this.state.code} onChange={this.handleInput} required/>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                        <a href="/" className="btn-blue btn-block cancels btn-cancel-white">Cancel</a>
                                    </div>
                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                        <button type="submit" className="bg-blue btn-blue btn-block continue btn-success-blue">Confirm</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            );
        } else {
            return (
                <div>
                    <div className="form-group text-primary text-center" style={{fontSize: "16px"}}>
                        Thank you! Your Account has been Successfully Registered!
                    </div>
                    <div className="form-group text-primary text-center">
                        You can user your account sign in to Ruhu
                    </div>
                    {/* <a href="#" onClick={this.showSignIn}  data-toggle="modal" data-target="#modal-sign-in" data-backdrop="static" data-keyboard="false" className="bg-blue submit btn-block text-center submit">Sign In Now</a> */}
                    <button type="submit" onClick={this.showSignIn} className="bg-blue btn-blue btn-block continue btn-success-blue">Sign In Now</button>
                </div>
            )
        }
    }
}

export default VerifyMobile;