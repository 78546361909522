function removeEmptyValueArray(arr) {
	let newArr = [];
		arr.forEach(value => { if (value) newArr.push(value); });
	return newArr;
}
function countObjectLength(object) { return Object.keys(object).length; }
function getLinkImageDefault() { return '/images/c1.png'; }
export {
	removeEmptyValueArray,
	getLinkImageDefault,
	countObjectLength
}