import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { setGroup, setCustomer, setPageSeo } from '../../actions/index'
import ModalSignIn from '../Modal/ModalSignIn'
import ModalRegister from '../Modal/ModalRegister'
import GoogleTranslate from '../Common/GoogleTranslate'
import { Cookies } from 'react-cookie'
import { page_seo_key } from '../../query'

const cookies = new Cookies()

class HeaderNoAds extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            quote_id: ''
        }
    }

    componentDidMount() {
        let {dispatch} = this.props;
        page_seo_key({})
            .then(data => {
                if (data.signal) {
                    dispatch(setPageSeo(data.data));
                }
            })
            .catch(err => console.log(err));
    }

    logoutVendor(e) {
        e.preventDefault();
        let {dispatch} = this.props;
        cookies.remove('customer', {path : '/', maxAge: 96969696})
        
        dispatch(setCustomer({}))
        window.location.href = '/'
    }

    render() {
        let {customer, dispatch} = this.props;
        return (
            <Fragment>
            <div className="header header-top">
                <div className="h-top">
                    <div className="container">
                        <ul className="nav navbar-nav navbar-left">
                            <li><GoogleTranslate /></li>
                        </ul>
                        <ul className="nav navbar-nav navbar-right">
                            <li>
                                <a href="/blogs" className="icon-flex">
                                    <img src="/images/forum.png" alt="Ruhu" className="ico icon-image"/>
                                    Blogs
                                </a>
                            </li>
                            {(Object.keys(customer).length !== 0) ? (
                                <li>
                                    <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="lang fontSemiBold">
                                        <img src="/images/user-logo.png" alt="Ruhu" className="avatar mr-5" />
                                        {`Hello ${customer.last_name}`}
                                        <span className="fa fa-angle-down ml-5"></span>
                                    </a>
                                    
                                    <ul className="dropdown-menu dropdown-menu-right">
                                        <li><a href="/profile">My profile</a></li>
                                        <li><a href="/my-quotes">My quotes</a></li>
                                        <li><a href="#" onClick={this.logoutVendor.bind(this)}>Logout</a></li>
                                    </ul>
                                </li>
                            ) :
                                <Fragment>
                                    <li>
                                        <a href="#" className="h-signin" data-toggle="modal" data-target="#modal-sign-in" data-backdrop="static" data-keyboard="false"><img src="/images/logo-signin.png" alt="Ruhu" className="ico" />Signin</a>
                                    </li>

                                    <li>
                                        <a href="#" data-toggle="modal" data-target="#modal-regis-free" data-backdrop="static" data-keyboard="false" className="h-signin">Sign up</a>
                                    </li>
                                </Fragment>
                            }
                        </ul>
                    </div>
                </div>
            </div >

            <ModalSignIn dispatch={dispatch}/>

            <ModalRegister dispatch={dispatch}/>

            {this.props.children}

            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    const {customer, quote, pageSeo} = state
    return { quote, customer, pageSeo }
}

export default connect(mapStateToProps)(HeaderNoAds)