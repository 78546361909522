import React, { Component } from 'react';

class ModalColLeft extends Component {
    render() {
        return (
            <div className="col-left">
                <div className="info">
                    <img src={'/images/check.png'} alt="Check"/>
                    <div className="text-center">BEST RATES & PRICING</div>
                    <div className="text-center">COMPHREHENSIVE PROFILING</div>
                    <div className="text-center">STANDARDISED & TRANSPARENT</div>
                    <div className="text-center">BIGGEST to SMALLEST<br/>
                    <span>of budgets</span></div>
                </div>
                <div className="contact" style={{fontSize: 22}}>
                    <img src={'/images/quote.png'} alt="Chat"/>
                    <div className="text-center">Contact Us !!</div>
                    <div className="text-center">+6563980900</div>
                </div>
            </div>
        );
    }
}

export default ModalColLeft;