import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Cookies } from 'react-cookie'
import {setCustomer} from '../../actions/index';
// import '../../../../public/css/blog.css';
import { BASE_URL_ADMIN } from '../../data/config'
import { customer_sign_in, customer_forgot_password } from '../../query'

const cookies = new Cookies()

class ModalSignIn extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            email: '',
            email_forgot: '',
            password: '',
            warningMessage: '',
            showLogin: true,
            success: false
        }
    }

    changeInput = (property, event) => {
        this.setState({ [property]: event.target.value });
    }

    showForgot = (e) => {
        e.preventDefault()
        this.setState(prevState => ({
            showLogin: !prevState.showLogin,
            success: false,
            email: '',
            email_forgot: '',
            password: ''
        }))
    }

    hideModal = (e) => {
        e.preventDefault()
        this.setState({
            showLogin: true
        })

        let $ = window.$;
        $('#modal-sign-in').modal('hide');
    }

    signIn = (e) => {
        e.preventDefault();
        let {dispatch, info , pageRedirect} = this.props;
        let self = this;
        let { email, password } = this.state;
        if (!email) {
            return this.setState({ warningMessage: 'Please enter email' });
        }
        if (!password) {
            return this.setState({ warningMessage: 'Please enter your password' });
        }
        customer_sign_in({ email, password, quote_id: info.quote_id })
            .then(data => {
                if (data.signal) {
                    
                    cookies.set('customer', data.data.token, { path: '/', maxAge: 96969696 })
                    let urlRedirect = (typeof pageRedirect == 'string') ? pageRedirect : '/';

                    setTimeout(() => {
                        window.location.href = '/'
                    }, 1000)
                    
                } else {
                    this.setState({ warningMessage: data.message })
                }
            })
            .catch(err => console.log(err))
    }

    submitForgot = (e) => {
        e.preventDefault()
        let {email_forgot} = this.state
        if (!email_forgot) return alert('Please enter your email address')

        customer_forgot_password({ email: email_forgot })
            .then(data => {
                if (data.signal) {
                    this.setState({
                        success: true
                    })
                } else {
                    alert(data.message)
                }
            })
            .catch(err => console.log(err))
    }
    componentDidMount() {
        
        this.initJquery();
    }

    initJquery() {
        let self = this;
        $(document).ready(function($) {
            $("#modal-sign-in").click(function(ev){
                if(ev.target != this) return;
                $('#modal-sign-in').modal('hide');
            });
        });                
    }
    render() {
        let { info, pageRedirect } = this.props
        let {showLogin, success} = this.state
        let urlRedirect = (typeof pageRedirect == 'string') ? pageRedirect : '/';
        return (
            <div className="modal fade" id="modal-sign-in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="signin-ruhu signin-ruhu-new">

                        {showLogin ? (
                            <div className="cont3nt-body">
                                <div className="title">
                                    <div className="text">Sign in</div>
                                    <a href="#" className="cancel" data-dismiss="modal">Cancel</a>
                                </div>
                                <div className="row">
                                    <div className="col-md-5 full-height">
                                        <form onSubmit={this.signIn.bind(this)}>
                                            <div className="content">
                                                <div className="form-group">
                                                    <input type="email" ref="email" value={this.state.email} onChange={this.changeInput.bind(this, 'email')} className="form-control input-mixx input-mixx-email" placeholder="Email" />
                                                </div>
                                                <div className="form-group">
                                                    <input type="password" value={this.state.password} onChange={this.changeInput.bind(this, 'password')} className="form-control input-mixx input-mixx-password" placeholder="Password" />
                                                </div>
                                                <p className="text-danger">{this.state.warningMessage}</p>
                                                <button type="submit" className="bg-blue action" style={{width: '100%'}}>Sign In</button>
                                            </div>
                                            <div className="text-center forgot-container"><a href="#" onClick={this.showForgot.bind(this)}>Forgot password</a></div>
                                        </form>
                                    </div>
                                    <div className="col-md-2 text-center">
                                        <div className="vertical-separator">
                                            <hr className="fading" />
                                            <span className="fa-stack fa-2x">
                                                <span className="fa fa-circle black fa-stack-2x"></span>
                                                <span className="fa-stack-1x white upcase subscript">or</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-md-5 full-height">
                                        <h4>Sign in with social media</h4>
                                        <a className="btn btn-social btn-facebook margin-bottom-10 full-width" href={`${BASE_URL_ADMIN}/auth/facebook-quote?code=${urlRedirect}`}>
                                            <span className="fa fa-facebook"></span>
                                            Sign in with Facebook
                                        </a>
                                        <a className="btn btn-social btn-google-plus full-width" href={`${BASE_URL_ADMIN}/auth/google-quote?code=${urlRedirect}`}>
                                            {/*<span className="fa fa-google-plus"></span>*/}
                                            <span><img src={'/images/google-plus-icon.png'} alt="Ruhu"/></span>
                                            Sign in with Google
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="cont3nt-body">
                                <div className="title">
                                    <div className="text">Forgot Password</div>
                                    <a href="#" className="closeX" onClick={this.hideModal.bind(this)}>
                                        <img src="/images/icon-close.png" alt="Ruhu" />
                                    </a>
                                </div>
                                <div className="content">
                                    {success ? (
                                        <div className="form-group">
                                            You should soon receive an email allowing you to reset your password. Please make sure to check your spam and trash if you can't find the email.
                                        </div>
                                    ) : (
                                        <Fragment>
                                            <div className="form-group">
                                                Please provide the username or email address that you used when you signed up for your account. We will send you an email that will allow you to reset your password.
                                            </div>
                                            <div className="form-group">
                                                <input type="text" className="form-control input-mixx input-mixx-email" value={this.state.email_forgot} onChange={this.changeInput.bind(this, 'email_forgot')} placeholder="Email"/>
                                            </div>
                                            <div className="form-group">
                                                <a href="#" className="bg-blue action" onClick={this.submitForgot.bind(this)}>Reset Password</a>
                                            </div>
                                        </Fragment>
                                    )}
                                    
                                </div>
                                <div className="text-center forgot-container"><a href="#" onClick={this.showForgot.bind(this)}>Login</a></div>
                            </div>
                        )}
                        
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { info, pageRedirect } = state;
    return {
        info, pageRedirect
    }
}

export default connect(mapStateToProps)(ModalSignIn);

