import React, { Component, Fragment } from 'react'
import Header from './Header';
import HeaderNoAds from '../Common/HeaderNoAds'

class Home extends Component {
    render() {
        return (
            <Fragment>
                <HeaderNoAds />
                <Header />
                {this.props.children}
            </Fragment>
        );
    }
}

export default Home