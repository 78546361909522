import { combineReducers } from 'redux'
import quote from './quote'
import group from './group'
import customer from './customer'
import info from './info'
import pageRedirect from './redirect'
import pageSeo from './page_seo'

export default combineReducers({
    quote,
    group,
    customer,
    info,
    pageRedirect,
    pageSeo
})