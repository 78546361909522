import React, { Component } from 'react';

class GoogleTranslate extends Component {
    componentDidMount() {
        this.googleTranslateInit()
    }

    googleTranslateInit() {

        let checkIfGoogleLoaded = setInterval(() => {

            if (google.translate.TranslateElement != null) {
                clearInterval(checkIfGoogleLoaded);

                this.googleTranslateElement('google_translate_element');
            }

        }, 100);

    }

    googleTranslateElement(idElement) {
        new google.translate.TranslateElement({pageLanguage: 'en', autoDisplay: true}, idElement)
        window.isShow = true
    }

    render() {
        return (
            <div id="google_translate_element"></div>
        );
    }
}

export default GoogleTranslate;