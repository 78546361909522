import { setQuote } from './quote'
import { setGroup } from './group'
import { setCustomer } from './customer'
import { setPageRedirect } from './redirect'
import { setPageSeo } from './page_seo'

export {
    setQuote,
    setGroup,
    setCustomer,
    setPageRedirect,
    setPageSeo
}