import React, { Component } from 'react';
import {BASE_URL_ADMIN} from '../../data/config';
import Slider from "react-slick";
import { get_home_vendor, get_vendor_detail } from '../../query'
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

class Vendor extends Component {
    constructor(props) {
        super(props)
        this.state = {
            allVendor: []
        }
    }

    componentWillMount() {
        get_home_vendor({})
            .then(data => {
                if (data.signal) {
                    this.setState({
                        allVendor: data.data
                    });
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    redirectVendor = (id, e) => {
        e.preventDefault()
        get_vendor_detail({ id })
            .then(res => {
                if (res.signal) {
                    if (res.data) {
                        window.open(`/${res.data.type}/${id}`, '_blank');         
                    }
                }
            })
            .catch(err => console.log(err))
    }

    renderImage = () => {
        let { allVendor } = this.state
        let content = allVendor.map((it, idx) => {
            return (
                <div className="flex1" key={idx}>
                    <div className="thumb">
                        {/* <a href="#"> */}
                            <img data-src={`${BASE_URL_ADMIN}${it.image}`} alt="Ruhu" height="228" className="lazyload"/>
                        {/* </a> */}
                        <div className="hover-img">
                            <a href="#" onClick={this.redirectVendor.bind(this, it.id)} >VIEW PROFILE</a>
                        </div>
                    </div>
                    <div className="name">
                        <a href="#">{it.name}</a>
                    </div>
                </div>
            )
        })

        if (allVendor.length < 4) {
            return (
                <div className="flex" style={{justifyContent: 'center'}}>
                    {content}
                </div>
            )
        } else {
            let settings = {
                infinite: true,
                slidesToShow: 4,
                slidesToScroll: 4,
                dots: false,
                speed: 500,
                autoplaySpeed: 3000,
                autoplay: true,
                responsive: [
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            }
    
            return (
                <Slider {...settings}>
                    {content}
                </Slider>
            )
        }
    }

    render() {
        return (
            <div>
                {this.renderImage()}
            </div>
        );
    }
}

export default Vendor;